<template>
  <b-container>
    <b-row>
      <b-col>
        <Heading type="h1" :title="trans('global.' + projectType + 's')" :button="{ title: trans('units.add_unit_' + projectType), modal: 'add-unit-modal'} " />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="!units" class="loading"></div>
        <div v-else>
          <b-row>
            <b-col v-if="projectUnits.length === 0">
              {{ trans('units.no_units_found') }}
            </b-col>
            <b-col v-for="unit in projectUnits" v-else :key="unit._id" sm="12">
              <b-card class="margin-top-16">
                <b-row>
                  <b-col>
                    <b-link :to="'/projects/' + project.slug + '/planner/' + unit.slug">
                      {{ unit.name }}
                    </b-link>
                  </b-col>
                  <b-col>
                    {{ unit.slug }}
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { mapState } from 'vuex'
  import Heading from '../../../Core/Heading'

  export default {
    name: 'ProjectUnits',
    components: {
      Heading
    },
    props: {
      project: {
        type: Object,
        required: true
      },
      projectType: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapState({
        units: state => state.units.units
      }),
      projectUnits () {
        // let obj = this.$store.state[val.text.store][val.text.store][parentId].find(item => item[val.text.searchin] === val.text.searchfor)
        return this.units.filter(unit => unit.projectId === this.project._id)
      }
    }
  }
</script>
