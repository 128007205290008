<template>
  <b-container id="project-overview" class="margin-top-60">
    <b-row class="align-content-strech flex-wrap">
      <b-col sm="6">
        <vary-card :title="trans('global.' + projectType + 's')" :description="trans('projects.units_in_this_project')" class="margin-bottom-30">
          <div v-if="projectUnits.length === 0" class="d-flex align-content-center flex-column">
            <p>{{ trans('units.no_units_found') }}</p>
            <b-button v-b-modal.add-unit-modal variant="primary" class="mx-auto my-1 padding-left-26 padding-right-26">
              {{ trans('units.add_unit_' + projectType) }}
            </b-button>
          </div>
          <b-list-group v-else class="room-list">
            <b-list-group-item v-for="( unit, index ) in projectUnits" :key="unit.slug" :to="'/projects/' + project.slug + '/planner/' + unit.slug">
              <span>{{ unit.name }}</span><span v-if="unitHasRooms(index)">{{ unitHasRooms(index) }} {{ trans('global.room').toLowerCase() }}</span><span v-else>{{ trans('rooms.no_rooms') }}</span>
            </b-list-group-item>
          </b-list-group>
        </vary-card>
      </b-col>
      <b-col sm="6">
        <vary-card :title="trans('global.events')" :description="trans('projects.eventslog_description')" class="margin-bottom-30">
          <div style="text-align: center; margin-bottom: 80px;">
            <i class="fas fa-align-justify margin-top-60" style="font-size: 300px; opacity: 0.2;"></i>
          </div>
        </vary-card>
      </b-col>
    </b-row>
    <b-row class="align-content-strech flex-wrap margin-top-26">
      <b-col sm="6">
        <vary-card :title="trans('global.tasks')" :description="trans('projects.tasks_description')" class="margin-bottom-30">
          <div style="text-align: center; margin-bottom: 80px;">
            <i class="fas fa-align-center margin-top-60" style="font-size: 100px; opacity: 0.2;"></i>
          </div>
        </vary-card>
      </b-col>
      <b-col sm="6">
        <vary-card :title="trans('global.roles')" :description="trans('projects.roles_description')" class="margin-bottom-30">
          <div style="text-align: center; margin-bottom: 80px;">
            <i class="fas fa-people-carry margin-top-60" style="font-size: 100px; opacity: 0.2;"></i>
          </div>
        </vary-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import VaryCard from '../../../Core/Card'

  export default {
    name: 'ProjectPlannerDashboard',
    components: {
      'vary-card': VaryCard
    },
    props: {
      project: {
        type: Object,
        required: true
      },
      projectType: {
        type: String,
        required: true
      },
      projectUnits: {
        type: Array,
        required: true
      }
    },
    methods: {
      unitHasRooms (id) {
        const rooms = this.projectUnits[id].rooms !== undefined && Object.keys(this.projectUnits[id].rooms).length > 0
        return (rooms ? Object.keys(this.projectUnits[id].rooms).length : false)
      }
    }
  }
</script>
