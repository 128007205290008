<template>
  <div id="content-wrapper">
    <TopNavbar :user-profile="userProfile" />
    <div v-if="!projectDataReady" class="loading">
      {{ trans('global.loading') }}
    </div>
    <div v-else id="content">
      <div id="project-topbar">
        <b-container fluid>
          <b-row>
            <b-col class="projectname padding-top-30">
              <p class="my-0 text-muted font-weight-light">
                {{ project.name }}
              </p>
              <h2>{{ trans('global.planning') }}</h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="tabnav p-0">
              <b-nav class="nav-underlined">
                <b-nav-item :active="activeTab === 'dashboard'" @click="toggleTab('dashboard')">
                  {{ trans('global.overview') }}
                </b-nav-item>
                <b-nav-item :active="activeTab === 'activities'" @click="toggleTab('activities')">
                  {{ trans('global.activities') }}
                </b-nav-item>
                <b-nav-item :active="activeTab === 'units'" @click="toggleTab('units')">
                  {{ trans('global.' + projectType + 's') }}
                </b-nav-item>
                <b-nav-item :active="activeTab === 'suppliers'" @click="toggleTab('suppliers')">
                  {{ trans('global.suppliers') }}
                </b-nav-item>
                <b-nav-item :active="activeTab === 'files'" @click="toggleTab('files')">
                  {{ trans('global.files') }}
                </b-nav-item>
                <b-nav-item :active="activeTab === 'settings'" @click="toggleTab('settings')">
                  {{ trans('global.settings') }}
                </b-nav-item>
              </b-nav>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <ProjectPlannerDashboard v-if="activeTab === 'dashboard'" id="project-planner-dashboard" :project="project" :project-type="projectType" :project-units="projectUnits"></ProjectPlannerDashboard>
      <ProjectPlannerUnits v-if="activeTab === 'units'" id="project-planner-units" :project="project" :project-type="projectType" :project-units="projectUnits"></ProjectPlannerUnits>
      <ProjectPlannerRoles v-if="activeTab === 'roles'" id="project-planner-roles"></ProjectPlannerRoles>
      <ProjectPlannerProducts v-if="activeTab === 'products'" id="project-planner-products"></ProjectPlannerProducts>
      <ProjectPlannerSettings v-if="activeTab === 'settings'" id="project-planner-settings"></ProjectPlannerSettings>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import TopNavbar from '../../../components/Navigation/TopNavbar'
  import ProjectPlannerDashboard from '../../../components/Views/Projects/Planner/Dashboard'
  import ProjectPlannerUnits from '../../../components/Views/Projects/Planner/Units'
  import ProjectPlannerRoles from '../../../components/Views/Projects/Planner/Roles'
  import ProjectPlannerProducts from '../../../components/Views/Projects/Planner/Products'
  import ProjectPlannerSettings from '../../../components/Views/Projects/Planner/Settings'

  export default {
    name: 'ProjectPlannerIndex',
    components: {
      TopNavbar,
      ProjectPlannerDashboard,
      ProjectPlannerUnits,
      ProjectPlannerRoles,
      ProjectPlannerProducts,
      ProjectPlannerSettings
    },
    data () {
      return {
        projectTypes: {
          1: 'cabin',
          2: 'apartment',
          3: 'house',
          4: 'industry',
          5: 'unit'
        }
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile,
        projects: state => state.projects.projects,
        configs: state => state.configs,
        units: state => state.units.units
      }),
      projectDataReady () {
        return this.project && this.units
      },
      project () {
        return this.projects.find(project => project.slug === this.$route.params.projectslug)
      },
      activeTab () {
        return (this.configs.projectTopbarMenu !== undefined && this.configs.projectTopbarMenu.activeTab !== '') ? this.configs.projectTopbarMenu.activeTab : 'dashboard'
      },
      projectType () {
        return this.project.type !== undefined ? this.projectTypes[this.project.type] : 1
      },
      projectUnits () {
        return this.units.filter(unit => unit.projectId === this.project._id)
      }
    },
    beforeMount () {
      this.$store.commit('configs/setProjectTopbarMenu', 'dashboard')
      this.updateBreadcrumb(
        [
          { text: this.trans('global.projects'), to: '/projects' },
          {
            text:
              {
                store: 'projects',
                searchin: 'slug',
                searchfor: this.$route.params.projectslug
              },
            to: '/projects/' + this.$route.params.projectslug
          },
          {
            text: this.trans('global.planner'), to: '#'
          }
        ]
      )
    },
    methods: {
      toggleTab (tab) {
        this.activetab = tab
        this.$store.commit('configs/setProjectTopbarMenu', tab)
      }
    }
  }
</script>
