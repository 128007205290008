<template>
  <b-container>
    <b-row>
      <b-col>
        <Heading type="h1" :title="trans('global.settings')" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="loading text-muted">
          <p>Kommende funksjoner</p>
          <ul>
            <li>
              Varslinger
            </li>
            <li>
              Roller
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Heading from '../../../Core/Heading'

  export default {
    name: 'ProjectPlannerSettings',
    components: {
      Heading
    }
  }
</script>
